import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11')
];

export const server_loads = [0];

export const dictionary = {
		"/": [~2],
		"/cerez-politikasi": [4],
		"/haberler": [~5],
		"/haberler/[...newsDetail]": [~6],
		"/iletisim": [~7],
		"/kategori/[categoryId]": [~8],
		"/search/[value]": [~9],
		"/uyelik": [10],
		"/yayin-detay/[publicationId]": [~11],
		"/[customPage]": [~3]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';